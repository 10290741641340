import { render } from "./DashboardSpaceDetailContainer.vue?vue&type=template&id=55a40843"
import script from "./DashboardSpaceDetailContainer.vue?vue&type=script&lang=js"
export * from "./DashboardSpaceDetailContainer.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "55a40843"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('55a40843', script)) {
    api.reload('55a40843', script)
  }
  
  module.hot.accept("./DashboardSpaceDetailContainer.vue?vue&type=template&id=55a40843", () => {
    api.rerender('55a40843', render)
  })

}

script.__file = "src/components/dashboard/DashboardSpaceDetailContainer.vue"

export default script